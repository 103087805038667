import { Alert, Box, Grid, LinearProgress, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { StyledFilterPanel, StripedDataGrid } from "../../theme/stripedTable";
import { useNavigate, useParams } from "react-router-dom";
import GridHeader from "../../component-library/GridHeader";
import {
    AssetDevicePreventiveMaintenanceEventDetails,
    AssetDevicePreventiveMaintenanceEventDetailsProps,
} from "../../models/assets/AssetDevicePreventiveMaintenanceEventDetails";
import { AssetDevicePreventiveMaintenanceEventDetailsHeader } from "../../models/assets/AssetDevicePreventiveMaintenanceEventDetailsHeader";
import {  DiagnosisTypes } from "../../models/assets/DiagnosisTypes";
import { useCallback, useEffect, useState } from "react";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { FormatDate } from "../../common/Formatters";
import { PmiDiagnosisRoundIcon } from "../../component-library/PmiDiagnosisRoundIcon";
import PmReportButton from "../../component-library/PmReportButton";
import { customSortComparators } from "../../utils/customSortComparators";
import { GridBackgroundColor } from "../../theme/theme";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { srnTypeKeys } from "../../config/data";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import {
    setGridFilter,
    setGridColumns,
    setGridSort,
} from "../../redux/reducers/assets/devicePreventiveMaintenanceReducer";
import { NoRowsOverlayWithIcon } from "../../component-library/NoRowsOverlay";
import { PmiDiagnosis } from "../../common/PmiDiagnosis";

const AssetsDevicePreventiveMaintenanceEventDetails = (props: AssetDevicePreventiveMaintenanceEventDetailsProps) => {
    const { gridFilter, gridColumns } = useSelector((state: StoreState) => state.devicePreventiveMaintenance);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const translations = {
        inspection: t("View Inspection"),
        result: t("Result"),
        serial: t("Serial"),
        category: t("Category"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        type: t("Type"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Preventive Maintenance Event"),
        breadCrumbTitle: t("Event"),
        inventoryItemId: t("Inventory Item Id"),
        view: t("View"),
        custAccountId: t("Customer Account Id"),
        inspector: t("Inspector"),
        sendForService: t("Send For Service"),
        actions: t("Actions"),
        markAsServiced: t("Mark as Serviced"),
        noMatch: t("We found no preventive maintenance events."),
        goBack: t("Please return back to "),
    };

    const [isHeaderLoading, setIsHeaderLoading] = useState(false);
    const [isHeaderError, setIsHeaderError] = useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [headerData, setHeaderData] = useState<AssetDevicePreventiveMaintenanceEventDetailsHeader>();
    const [diagnosisTypes, setDiagnosisTypes] = useState<DiagnosisTypes[]>([]);
    const params = useParams<{ pmiId: string }>();
    const { get } = useFetch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();

    const getHeaders = useCallback(async () => {
        setIsHeaderLoading(true);
        setIsHeaderError(false);
        const uri = `${requestConnectCareInventory.AssetDevicePreventiveMaintenanceEventDetailsHeaderList}?pmiId=${params.pmiId}`;
        const response = await get<AssetDevicePreventiveMaintenanceEventDetailsHeader>(uri, true);
        const result = response as AssetDevicePreventiveMaintenanceEventDetailsHeader;
        if (result) {
            setHeaderData(result);
        } else {
            setIsHeaderError(true);
        }
        setIsHeaderLoading(false);
    }, [params.pmiId, get]);

    const getDiagnosisTypes = useCallback(async () => {
        const uri = `${requestConnectCareInventory.getdiagnosistypes}`;
        const response = await get<DiagnosisTypes[]>(uri);
        const result = response as DiagnosisTypes[];
        if (result) {
            setDiagnosisTypes(result);
        }
    }, [get]);

    const shouldHideButton = (): boolean => {
        return props.assetDevicePreventiveEventDetailsData.every((data) => data.diagnosis === "Inv Only");
    };

    useEffect(() => {
        if (!initialDataLoaded) {
            setInitialDataLoaded(true);
            getHeaders();
            getDiagnosisTypes();
        }
    }, [getHeaders, getDiagnosisTypes, initialDataLoaded]);

    /**
     * Sets the action column, action button menu items.
     * @param params
     * @returns a list of action menu items.
     */
    const menuArrayItems = (params: GridRowModel<AssetDevicePreventiveMaintenanceEventDetails>): IActionMenuItems[] => {
        return [
            {
                name: translations.inspection,
                onClick: () => navigate(`/assets/devicepm/event/${headerData?.customerAccountId}/${params.inspection}`),
            },
            {
                name: translations.sendForService,
                onClick: () =>
                    navigate(
                        `/servicerequest/add-items/${
                            srnTypeKeys.Serialized
                        }/${headerData?.customerAccountId}/${encodeURIComponent(params.serial)}/${
                            params.inventoryItemId
                        }`
                    ),
                disable: params.diagnosis === PmiDiagnosis.OK,
                hide: !params.canSendForService || params.isIndirectFiltered,
            },
            {
                name: translations.markAsServiced,
                onClick: () => {},
                disable: true,
            },
        ];
    };

    const columns: GridColDef[] = [
        {
            field: "actions",
            headerName: translations.actions,
            renderHeader: () => translations.actions,
            minWidth: 150,
            renderCell: (params) => <ConnectCareActionButton menuItems={menuArrayItems(params.row)} />,
            flex: 1,
            headerClassName: "padding",
        },
        {
            field: "result",
            headerName: translations.result,
            renderHeader: () => translations.result,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => <PmiDiagnosisRoundIcon {...params} />,
            sortComparator: (v1: string, v2: string) => customSortComparators.sortByDeviceEvent(diagnosisTypes, v1, v2),
        },
        {
            field: "serial",
            headerName: translations.serial,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    style={{ textDecoration: "none", color: "blue.connectCare2" }}
                    onClick={() => {
                        navigate(
                            `/assets/${headerData?.customerAccountId}/${
                                params.row.inventoryItemId
                            }/${encodeURIComponent(params.row.serial)}`
                        );
                    }}>
                    {params.row.serial}
                </Link>
            ),
            minWidth: 150,
            flex: 1,
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => translations.category,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            minWidth: 180,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 130,
            flex: 1,
        },
        {
            field: "type",
            headerName: translations.type,
            renderHeader: () => translations.type,
            minWidth: 130,
            flex: 1,
        },
    ];

    return (
        <Box
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}
            data-testid="asset-device-pm-event-details">
            <BreadCrumb breadCrumbs={BreadCrumbList.inspectionEvent} />
            <Box p={theme.spacing(2)}>
                <Grid>
                    {isHeaderError && (
                        <Grid
                            item
                            data-testid={"adpm-header-error"}
                            xs={12}
                            sx={{ mx: 2, mb: 2 }}>
                            <Alert severity="error">{translations.apiError}</Alert>
                        </Grid>
                    )}
                    {isHeaderLoading && <LinearProgress />}
                    {!isHeaderLoading && (
                        <Grid>
                            <GridHeader>
                                <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    alignItems={"center"}
                                    my={1}
                                    px={1}>
                                    <Grid item>
                                        <Typography
                                            fontFamily={"Lato"}
                                            color={"#8E8E8E"}
                                            fontWeight={500}
                                            fontSize={"12px"}>
                                            {headerData?.customerDisplay}
                                        </Typography>
                                        <Typography
                                            fontFamily={"Lato"}
                                            color={"#1F3767"}
                                            fontWeight={700}
                                            fontSize={"29px"}>
                                            {translations.title +
                                                ": " +
                                                FormatDate(headerData?.eventDate).toUpperCase()}
                                        </Typography>
                                        <Typography
                                            fontFamily={"Lato"}
                                            color={"#8E8E8E"}
                                            fontWeight={400}
                                            fontSize={"12px"}>
                                            {translations.inspector + ": " + headerData?.inspector}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <PmReportButton
                                            pmiId={Number(params.pmiId)}
                                            custAccountId={headerData?.customerAccountId ?? 0}
                                            hideButton={shouldHideButton()}
                                        />
                                    </Grid>
                                </Grid>
                            </GridHeader>
                        </Grid>
                    )}
                </Grid>
                <Box
                    py={theme.spacing(2)}
                    data-testid="asset-due-for-pm-list-grid-box">
                    <Grid container>
                        <Grid
                            item
                            xs>
                            <Box
                                bgcolor="bg.light"
                                height="calc(100vh - 260px)">
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    initialState={{
                                        filter: {
                                            filterModel: gridFilter,
                                        },
                                        sorting: {
                                            sortModel: [{ field: "result", sort: "asc" }],
                                        },
                                    }}
                                    getRowId={(row) => row.serial}
                                    rows={props.assetDevicePreventiveEventDetailsData}
                                    columns={columns}
                                    columnBuffer={7}
                                    density="compact"
                                    slots={{
                                        toolbar: () => CustomToolbar(exportExcelFileName.assets),
                                        loadingOverlay: LinearProgress,
                                        filterPanel: StyledFilterPanel,
                                        noRowsOverlay: () =>
                                            NoRowsOverlayWithIcon({
                                                primaryText: translations.noMatch,
                                                secondaryText: "",
                                            }),
                                    }}
                                    loading={props.isLoading}
                                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                    columnVisibilityModel={gridColumns}
                                    onColumnVisibilityModelChange={(columnModel) =>
                                        dispatch(setGridColumns(columnModel))
                                    }
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default AssetsDevicePreventiveMaintenanceEventDetails;
