import { Alert, Box, Button, Grid, LinearProgress, Typography, FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setToast } from "../../redux/reducers/toastSlice";
import { FormatDate } from "../../common/Formatters";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { AssetMaintenancePlan } from "../../models/assets/AssetMaintenancePlan";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useFetch } from "../../services/useFetch";
import { setHasUnsavedChanges } from "../../redux/reducers/assets/assetsSlice";
import { useAppDispatch } from "../../hooks/useReduxHooks";

const MaintenancePlan = ({
    customerAccountId,
    serialNumber,
    inventoryItemId,
    editModeFlag = false,
}: {
    customerAccountId?: string;
    serialNumber: string;
    inventoryItemId?: string;
    setPageKey: Dispatch<SetStateAction<number>>;
    editModeFlag: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { get, put } = useFetch();
    const [isError, setIsError] = useState(false);
    const [editMode, setEditMode] = useState(editModeFlag);
    const [isLoading, setIsLoading] = useState(false);
    const [assetMaintenancePlan, setAssetMaintenancePlan] = useState<AssetMaintenancePlan>();

    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [interval, setInterval] = useState("6");
    const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs(assetMaintenancePlan?.note));

    const translations = {
        assetPlan: t("Planned/Unplanned"),
        interval: t("interval"),
        months: t("months"),
        dueDate: t("due date override"),
        apiError: t("System Error: API is not available at this time!"),
        editButton: t("Edit"),
        cancelButton: t("Cancel"),
        saveButton: t("Save"),
        editSuccessful: t("Maintenance Plan has been updated successfully."),
        failed: t("Maintenance Plan failed to updated."),
    };

    const SectionField = styled(Typography)({
        color: "#8E8E8E",
        letterSpacing: "1px",
        lineHeight: "14px",
        fontFamily: "Lato",
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
    });

    const SectionFieldValue = styled(Typography)({
        color: "#1F1F1F",
        fontStyle: "Lato",
        paddingTop: "7px",
        fontSize: 12,
        fontWeight: 600,
        textTransform: "uppercase",
    });

    const getAssetMaintenancePlan = useCallback(async () => {
        setIsUnauthorized(false);
        setIsError(false);
        setIsLoading(true);

        const uri = `${
            requestConnectCareInventory.AssetMaintenancePlan
        }?customerAccountId=${customerAccountId}&serialNumber=${encodeURIComponent(
            serialNumber
        )}&inventoryItemId=${inventoryItemId}`;

        const response = (await get<AssetMaintenancePlan>(uri, true, (error: Error) => {
            if (error.name === "401") {
                setIsUnauthorized(true);
            } else {
                setIsError(true);
            }
        })) as AssetMaintenancePlan;

        if (response) {
            setChecked(!!(response.plannerActive === "Planned" || editModeFlag));
            setInterval(Number(response.scheduledMonths) > 0 ? response.scheduledMonths : "6");
            setAssetMaintenancePlan(response);
        }
        setIsLoading(false);
    }, [customerAccountId, editModeFlag, get, inventoryItemId, serialNumber]);

    const saveMaintenancePlan = async () => {
        setIsError(false);
        setIsLoading(true);
        const uri = `${requestConnectCareInventory.UpdateAssetMaintenancePlan}`;
        let inputDate = new Date(dayjs(dueDate, "DD-MMM-YYYY").format("YYYY/MM/DD"));
        inputDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000);

        const payload = {
            plannerActive: checked ? "Y" : "N",
            masterOrganizationId: assetMaintenancePlan?.masterOrganizationId ?? 0,
            preferenceNumber: assetMaintenancePlan?.preferenceNumber ?? 0,
            scheduledMonths: interval.toString(),
            dueDateOverride: inputDate,
            customerAccountId: customerAccountId,
            customerItemId: assetMaintenancePlan?.customerItemId ?? 0,
        };
        const response = await put(uri, payload, false, () => {
            dispatch(
                setToast({
                    toastMessage: translations.failed,
                    toastType: ToastTypes.Error,
                })
            );
            setIsError(true);
        });
        if (response) {
            dispatch(
                setToast({
                    toastMessage: translations.editSuccessful,
                    toastType: ToastTypes.Success,
                })
            );
            getAssetMaintenancePlan();
        }
        setIsLoading(false);
        dispatch(
            setToast({
                toastMessage: translations.editSuccessful,
                toastType: ToastTypes.Success,
            })
        );
        setEditMode(false);
    };

    useEffect(() => {
        if (!initialDataLoaded) {
            getAssetMaintenancePlan();
        }
    }, [getAssetMaintenancePlan, initialDataLoaded]);

    useEffect(() => {
        if (!initialDataLoaded) {
            setInitialDataLoaded(true);
        }
    }, [initialDataLoaded]);

    const handleIntervalChange = (event: SelectChangeEvent) => {
        setInterval(event.target.value);
        dispatch(setHasUnsavedChanges(true));
    };

    const resetView = () => {
        dispatch(setHasUnsavedChanges(false));
        setEditMode(false);
        setChecked(assetMaintenancePlan?.plannerActive === "Planned");
        setInterval(
            assetMaintenancePlan && Number(assetMaintenancePlan.scheduledMonths) > 0
                ? assetMaintenancePlan.scheduledMonths
                : "6"
        );
    };

    const onhandleSave = () => {
        resetView();
        saveMaintenancePlan();
        dispatch(setHasUnsavedChanges(false));
        setEditMode(false);
    };

    const SwitchButton = styled((props: SwitchProps) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 35,
        height: 20,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 1.5,
            transitionDuration: "300ms",
            marginLeft: 7,
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.mode === "dark" ? "#CCCCCC" : "#30D987",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 15,
            height: 15,
            marginLeft: "-5px",
            marginTop: "1px",
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#CCCCCC" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    return (
        <>
            {isUnauthorized ? (
                <NotAuthorizeDisplay />
            ) : (
                <Box
                    bgcolor="bg.white"
                    border="1px solid #DFE0EB"
                    minHeight="31vh"
                    data-testid="asset-maintenance-plan"
                    sx={{ top: "336px", left: "128px", width: "100%" }}>
                    {isError && !isLoading && (
                        <Grid
                            item
                            xs={12}
                            mx={0}
                            mb={2}>
                            <br />
                            <Alert
                                data-testid="maintenance-plan-error"
                                severity="error">
                                {translations.apiError}
                            </Alert>
                        </Grid>
                    )}
                    {isLoading && <LinearProgress data-testid="progress-bar"></LinearProgress>}
                    {!isLoading && (
                        <>
                            <Grid
                                container
                                sx={{ px: "66px", pt: "71px" }}>
                                <Box paddingRight={3}>
                                    <SectionField>{translations.assetPlan}</SectionField>
                                    <SectionFieldValue data-testid="maintenance-plan">
                                        {!editMode ? (
                                            assetMaintenancePlan?.plannerActive === "Unplanned" ? (
                                                "Unplanned"
                                            ) : (
                                                "Planned"
                                            )
                                        ) : (
                                            <>
                                                <SwitchButton
                                                    sx={{ m: 1 }}
                                                    checked={checked}
                                                    onChange={() => {
                                                        setChecked(!checked);
                                                        dispatch(setHasUnsavedChanges(true));
                                                    }}
                                                />
                                                {checked ? "Planned" : "Unplanned"}
                                            </>
                                        )}
                                    </SectionFieldValue>
                                </Box>
                                {checked && (
                                    <>
                                        <Box paddingRight={4}>
                                            <SectionField>
                                                {translations.interval} / {translations.months}
                                            </SectionField>
                                            {!editMode ? (
                                                <SectionFieldValue>
                                                    {assetMaintenancePlan &&
                                                    Number(assetMaintenancePlan.scheduledMonths) > 0
                                                        ? Number(assetMaintenancePlan.scheduledMonths)
                                                        : "6"}
                                                </SectionFieldValue>
                                            ) : (
                                                <FormControl fullWidth={true}>
                                                    <Select
                                                        sx={{ m: 1, width: "150px", height: "29px" }}
                                                        labelId="asset-maintenance-interval"
                                                        id="asset-maintenance-interval"
                                                        data-testid="asset-maintenance-interval"
                                                        defaultValue={interval}
                                                        value={interval}
                                                        onChange={handleIntervalChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 400,
                                                            },
                                                        }}>
                                                        {Array.from({ length: 25 }).map((_it, index) => {
                                                            if (index === 0) {
                                                                return false;
                                                            }
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={index}>
                                                                    {index}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Box>
                                        <Box paddingRight={4}>
                                            <SectionField>{translations.dueDate}</SectionField>
                                            {!editMode ? (
                                                <SectionFieldValue>
                                                    {assetMaintenancePlan?.note !== null
                                                        ? FormatDate(assetMaintenancePlan?.note)
                                                        : ""}
                                                </SectionFieldValue>
                                            ) : (
                                                <Typography
                                                    color={"#1F1F1F"}
                                                    fontFamily={"Lato"}
                                                    fontSize={12}
                                                    fontWeight={400}
                                                    textTransform={"uppercase"}
                                                    paddingTop={"7px"}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={dueDate}
                                                            defaultValue={dayjs(new Date())}
                                                            onChange={(newValue) => {
                                                                setDueDate(newValue);
                                                                dispatch(setHasUnsavedChanges(true));
                                                            }}
                                                            sx={{
                                                                backgroundColor: "#FFFFFF",
                                                                width: "190px",
                                                                "& .css-apts3k-MuiInputBase-root-MuiOutlinedInput-root":
                                                                    {
                                                                        height: "30px",
                                                                    },
                                                            }}
                                                            slotProps={{
                                                                textField: { size: "small" },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Typography>
                                            )}
                                        </Box>
                                    </>
                                )}
                            </Grid>
                            <Grid
                                container
                                sx={{ px: "66px", pt: "87px", pb: "35px" }}>
                                {!editMode ? (
                                    <Button
                                        data-testid="editButton"
                                        onClick={() => setEditMode(true)}
                                        variant="text"
                                        style={{
                                            fontSize: "12px",
                                            color: "#FFFFFF",
                                            width: "89px",
                                            height: "24px",
                                            backgroundColor: "#3E8EDE",
                                        }}>
                                        {translations.editButton}
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            onClick={resetView}
                                            variant="text"
                                            style={{
                                                fontSize: "12px",
                                                color: "#FFFFFF",
                                                width: "89px",
                                                height: "24px",
                                                backgroundColor: "#CCCCCC",
                                                marginRight: "6px",
                                            }}>
                                            {translations.cancelButton}
                                        </Button>
                                        <Button
                                            onClick={onhandleSave}
                                            variant="text"
                                            style={{
                                                fontSize: "12px",
                                                color: "#FFFFFF",
                                                width: "89px",
                                                height: "24px",
                                                backgroundColor: "#3E8EDE",
                                            }}>
                                            {translations.saveButton}
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

export default MaintenancePlan;
