import { useEffect, useState, useCallback } from "react";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { EducationCEEvents } from "../../models/education/Education";
import { claimTypes } from "../../config/claimTypes";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useTranslation } from "react-i18next";
import { AxisConfig, BarSeriesType } from "@mui/x-charts";
import { blue, widget } from "../../theme/colors";
import ErrorMessage from "../dashboard/ErrorMessage";
import MetricsChart from "../../component-library/MetricsChart";
import { MetricsColor } from "../../common/MetricsColor";

export default function EducationAllCEEvents({
    fromDate,
    toDate,
    isCompletedCEEvents,
}: {
    readonly fromDate: string;
    readonly toDate: string;
    readonly isCompletedCEEvents: boolean;
}) {
    const { t } = useTranslation();
    const { get } = useFetch();

    const [educationCEEvents, setEducationCEEvents] = useState<EducationCEEvents[] | []>([]);
    const [isStatusError, setIsStatusError] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const CEEventsUrl = isCompletedCEEvents
        ? requestConnectCareEducations.EducationCompletedCEEvents
        : requestConnectCareEducations.EducationCompletedNonCEEvents;
    let totalEvents = 0;

    const translations = {
        completedCEEvents: t("Completed CE Events By Department"),
        completedNonCEEvents: t("Completed Non CE Events By Department"),
        errorMessage: t("Data is unavailable or there is not enough data to display this metric."),
    };

    /**
     * Gets all the data for our pie charts. Only display labels for the top 7 by event count.
     * @param showAll Show all the values on the pie chart, or show a fixed amount of items on the pie chart, with the rest being grouped together.
     */

    const getSeries = (showAll: boolean): BarSeriesType[] => {
        const sorted = educationCEEvents.sort(sortEducationEventsByDescending); //Sort by highest first
        const labelCount = showAll ? educationCEEvents.length : 4;
        let withoutLabelCount = 0;
        let barChartData: BarSeriesType[] = [];

        for (let i = 0; i < sorted.length; i++) {
            if (sorted[i]) {
                //if educationCEEvents[i] is not undefined
                totalEvents += sorted[i]?.eventCount ?? 0; //Sum the total event count
                if (i < labelCount) {
                    //for the first n items add the label
                    const barDataWithLabel = GetBarChartDataWithLabel(sorted[i]!, i);
                    barChartData.push(barDataWithLabel);
                } else {
                    //Sum the rest with no label
                    withoutLabelCount += sorted[i]?.eventCount ?? 0;
                }
            }
        }
        if (withoutLabelCount > 0) {
            //show the unlabeled
            let leftoverBarChartData: BarSeriesType = {
                type: "bar",
                data: [withoutLabelCount],
                label: `+${sorted.length - labelCount} more...`,
                color: widget.red,
            };
            barChartData.push(leftoverBarChartData);
        }
        return barChartData;
    };

    /**
     * Sorts our education events by event count descending.
     * @param a An education event.
     * @param b An education event.
     */
    const sortEducationEventsByDescending = (a: EducationCEEvents, b: EducationCEEvents) => {
        return b.eventCount - a.eventCount;
    };

    /**
     * Gets a piece of the pie chart and adds a label to it.
     * @param e The education event.
     * @param id The education event Id.
     * @returns {PieValueType} The pie chart data with labels.
     */
    const GetBarChartDataWithLabel = (e: EducationCEEvents, id: number): BarSeriesType => {
        let barChartValueType: BarSeriesType = {
            type: "bar",
            data: [e.eventCount],
            label: e.department,
            color: insightMetricsColor(id),
            id: id,
        };
        return barChartValueType;
    };

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    /**
     * Chooses a color for metrics bar chart.
     * @param index To get the color at index position
     * @returns The color
     */
    const insightMetricsColor = useCallback((index: number): string => {
        let color: string = MetricsColor[index] ?? widget.blue;
        return color;
    }, []);

    const getCEEvents = useCallback(async () => {
        setIsStatusError(false);
        setIsStatusLoading(true);
        const uri = `${CEEventsUrl}?from=${fromDate}&to=${toDate}`;
        const response = await get<EducationCEEvents[]>(uri, true, () => {
            setIsStatusError(true);
        });

        if (!isStatusError) {
            setEducationCEEvents(response as EducationCEEvents[]);
        }
        setIsStatusLoading(false);
    }, [fromDate, toDate, get, CEEventsUrl, isStatusError]);

    useEffect(() => {
        if (hasViewEducationEvents) {
            getCEEvents();
        }
    }, [hasViewEducationEvents, getCEEvents]);

    return (
        <Box
            data-testid={isCompletedCEEvents ? "education-ce-completed" : "education-ce-noncompleted"}
            border={1}
            color={blue.lightGrayishBlue}
            textAlign={"left"}
            minHeight={"350"}
            minWidth={"350px"}
            sx={{ marginLeft: "20px" }}
            my={2}
            p={1}>
            {isStatusLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {(!isStatusLoading || isStatusError) && educationCEEvents?.length <= 0 && (
                <Box mt={5}>
                    <ErrorMessage message={translations.errorMessage} />
                </Box>
            )}
            {!isStatusLoading && educationCEEvents?.length > 0 && (
                <Box onClick={handleDialogOpen}>
                    <MetricsChart
                        heading={
                            isCompletedCEEvents ? translations.completedCEEvents : translations.completedNonCEEvents
                        }
                        getSeries={getSeries(false)}
                        xAxis={[
                            {
                                scaleType: "band",
                                data: [`(${totalEvents})`],
                                categoryGapRatio: 0,
                            } as AxisConfig<"band">,
                        ]}
                    />
                </Box>
            )}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth={true}>
                <Box height={350}>
                    <MetricsChart
                        heading={
                            isCompletedCEEvents ? translations.completedCEEvents : translations.completedNonCEEvents
                        }
                        getSeries={getSeries(true)}
                        xAxis={[
                            {
                                scaleType: "band",
                                data: [`(${totalEvents})`],
                                categoryGapRatio: 0,
                            } as AxisConfig<"band">,
                        ]}
                    />
                </Box>
            </Dialog>
        </Box>
    );
}
