import Box from "@mui/material/Box";
import { Button, Stack, TextField } from "@mui/material";
import { SectionField, SectionFieldValue } from "../../theme/theme";
import { text } from "../../../src/theme/colors";
import { ChangeEvent } from "react";
import { CurrentUser } from "../../models/authorization/CurrentUser";
import { useTranslation } from "react-i18next";

export interface IUserProfileEditFormProps {
    handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => void;
    updatedUserProfile: CurrentUser;
    userProfileDetails: CurrentUser;
    resetDefaultState: () => void;
    updateUserProfileData: () => Promise<void>;
}

export const UserProfileEditForm = (props: IUserProfileEditFormProps) => {
    const { handleChange, updatedUserProfile, userProfileDetails, resetDefaultState, updateUserProfileData } = props;
    const { t } = useTranslation();
    const translations = {
        name: t("Name"),
        title: t("Title"),
        email: t("Email"),
        firstName: t("First Name"),
        lastName: t("Last Name"),
        jobTitle: t("Title"),
        save: t("Save"),
        cancel: t("Cancel"),
        firstNameValidationError: t("First Name cannot be blank"),
        lastNameValidationError: t("Last Name cannot be blank"),
    };

    return (
        <Stack
            data-testid="user-profile-edit-mode-stack"
            direction={"row"}
            paddingLeft={8}
            paddingTop={5}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            useFlexGap
            flexWrap="wrap">
            <Box>
                <SectionField>{translations.name}</SectionField>
                <Stack
                    mt={2}
                    direction="row"
                    spacing={2}>
                    <TextField
                        required
                        label={translations.firstName}
                        aria-label={translations.firstName}
                        color="primary"
                        inputProps={{
                            "data-testid": "firstName",
                        }}
                        onChange={(e) => props.handleChange(e, "firstName")}
                        size="small"
                        sx={{ bgcolor: "bg.white", borderColor: "blue" }}
                        value={updatedUserProfile.firstName}
                        error={updatedUserProfile.firstName?.length === 0}
                        helperText={
                            updatedUserProfile.firstName?.length === 0 ? translations.firstNameValidationError : ""
                        }
                    />
                    <TextField
                        required
                        label={translations.lastName}
                        aria-label={translations.lastName}
                        color="primary"
                        inputProps={{
                            "data-testid": "lastName",
                        }}
                        onChange={(e) => handleChange(e, "lastName")}
                        size="small"
                        sx={{ bgcolor: "bg.white" }}
                        value={updatedUserProfile.lastName}
                        error={updatedUserProfile.lastName?.length === 0}
                        helperText={
                            updatedUserProfile.lastName?.length === 0 ? translations.lastNameValidationError : ""
                        }
                    />
                </Stack>
            </Box>
            <Box>
                <SectionField>{translations.title}</SectionField>
                <TextField
                    label={translations.title}
                    aria-label={translations.title}
                    color="primary"
                    inputProps={{
                        "data-testid": "jobTitle",
                    }}
                    onChange={(e) => handleChange(e, "jobTitle")}
                    size="small"
                    variant="outlined"
                    sx={{ bgcolor: "bg.white", mt: 2 }}
                    value={updatedUserProfile.jobTitle}
                />
            </Box>
            <Box>
                <SectionField sx={{ color: text.textgray }}>{translations.email}</SectionField>
                <SectionFieldValue>{userProfileDetails.email}</SectionFieldValue>
            </Box>
            <Box
                display={"flex"}
                mt={3}>
                <Button
                    aria-label={translations.cancel}
                    variant="contained"
                    style={{
                        color: "#FFFFFF",
                        backgroundColor: text.textgray2,
                    }}
                    onClick={() => {
                        resetDefaultState();
                    }}>
                    {translations.cancel}
                </Button>
                <Box
                    component="span"
                    sx={{ minWidth: "1vw", maxWidth: "1vw" }}
                />
                <Button
                    data-testid="save-button"
                    aria-label={translations.save}
                    variant="contained"
                    onClick={() => {
                        updateUserProfileData();
                    }}>
                    {translations.save}
                </Button>
            </Box>
        </Stack>
    );
};
