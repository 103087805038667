import { Alert, Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { srnTypeKeys } from "../../config/data";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { requestConnectCareInventory } from "../../services/apiPaths";
import AssetDetail from "./AssetDetail";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AssetHeaderDetail } from "../../models/assets/AssetHeaderDetail";
import { Link } from "react-router-dom";
import { FormatDate } from "../../common/Formatters";
import {
    CalendarMonthOutlined,
    FormatListBulletedRounded,
    WatchLaterRounded,
    StickyNote2Rounded,
} from "@mui/icons-material";
import { AssetDetail as AssetDetailDto } from "../../models/assets/AssetDetail";
import AssetDetailEdit from "./AssetDetailEdit";
import AssetHistoryDetails from "./AssetHistoryDetails";
import AssetNotes from "./AssetNotes";
import MaintenancePlan from "./MaintenancePlan";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import AssetItemNotFound from "./AssetItemNotFound";
import { InventoryItemId } from "../../models/assets/InventoryItemId";
import { useFetch } from "../../services/useFetch";
import getQueryStringParam from "../../utils/getQueryStringParam";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { setHasUnsavedChanges } from "../../redux/reducers/assets/assetsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";

const AssetTabs = ({
    custAccountId,
    serialNumber,
    inventoryItemId,
    setPageKey,
}: {
    custAccountId?: string;
    serialNumber: string;
    inventoryItemId?: string;
    setPageKey: Dispatch<SetStateAction<number>>;
}) => {
    const dispatch = useAppDispatch();
    const { hasUnsavedChanges } = useAppSelector((state) => state.assets);
    const { t } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const hasViewAssets = AuthLibrary.checkClaim(claimTypes.ViewAllAssets);
    const hasViewMaintenanceTab = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.ViewMaintenanceTab,
        parseInt(custAccountId!)
    );
    const hasViewAllAssets = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.ViewAllAssets,
        parseInt(custAccountId!)
    );
    const [assetDetail, setAssetDetail] = useState<AssetHeaderDetail>();
    const [isHeaderDetailError, setIsHeaderDetailError] = useState(false);
    const [isNotesCountApiError, setIsNotesCountApiError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotesCountLoading, setIsNotesCountLoading] = useState(false);
    const [isDetailTabReloaded, setIsDetailTabReloaded] = useState(false);
    const [editDetailsTab, setEditDetailsTab] = useState(false);
    const [assetDetailTab, setAssetDetailTab] = useState<AssetDetailDto>();
    const [assetNotesCount, setAssetNotesCount] = useState<number>(0);
    const [editModeFlag, setEditModeFlag] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [newTabValue, setNewTabValue] = useState<string>("History");

    const translations = {
        id: t("Id"),
        serial: t("Serial"),
        category: t("Category"),
        description: t("Description"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        customer: t("Customer"),
        lastService: t("Last Service"),
        nextService: t("Next Service"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Assets"),
        enablePlanner: t("Enable Planner"),
        createServiceRequest: t("Create Service Request"),
        history: t("History"),
        details: t("Details"),
        maintenancePlan: t("Maintenance Plan"),
        notes: t("Notes"),
        confirmationModalTitle: t("Unsaved Changes"),
        confirmationModalContent: t("Do you want to keep editing or discard changes?"),
        confirmationModalDiscardChanges: t("Discard Changes"),
        confirmationModalKeepEditing: t("Keep Editing"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.assetDetails)}`,
    };

    const tabIds = {
        history: "History",
        details: "Details",
        maintenancePlan: "Maintenance Plan",
        notes: "Notes",
    };

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.history,
                icon: <WatchLaterRounded />,
                children: <>{translations.history}</>,
            },
            {
                tabId: tabIds.details,
                icon: <FormatListBulletedRounded />,
                hide: !hasViewAllAssets,
                children: <>{translations.details}</>,
            },
            {
                tabId: tabIds.maintenancePlan,
                icon: <CalendarMonthOutlined />,
                hide: !hasViewMaintenanceTab,
                children: <>{translations.maintenancePlan}</>,
            },
            {
                tabId: tabIds.notes,
                hide: !hasViewAllAssets,
                icon: <StickyNote2Rounded />,
                children: (
                    <TabLoader
                        isLoading={isNotesCountLoading}
                        labelText={translations.notes}
                        isApiError={isNotesCountApiError}
                        count={assetNotesCount}
                    />
                ),
            },
        ],
        [
            assetNotesCount,
            isNotesCountApiError,
            isNotesCountLoading,
            tabIds.details,
            tabIds.history,
            tabIds.maintenancePlan,
            tabIds.notes,
            translations.details,
            translations.history,
            translations.maintenancePlan,
            translations.notes,
            hasViewMaintenanceTab,
            hasViewAllAssets,
        ]
    );

    // Below state is to set up the initial tab to show while landing into Order Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabId === getQueryStringParam("tab")) ?? tabs[0]
    );

    const handleTabChangeForMaintenancePlan = (newValue: string) => {
        setEditModeFlag(true);
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
        setNewTabValue(newValue);
        setEditModeFlag(false);
        hasUnsavedChanges
            ? setShowConfirmModal(hasUnsavedChanges)
            : setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const handleDiscard = (newTabValue: string) => {
        dispatch(setHasUnsavedChanges(false));
        setCurrentTab(tabs.find((x) => x.tabId === newTabValue));
        setShowConfirmModal(false);
    };

    const handleEdit = () => {
        setShowConfirmModal(false);
    };

    /**
     * Gets data from invenotry api and sets @type {AssetHeaderDetail}.
     */
    const getAssetHeaderDetails = useCallback(async () => {
        setIsLoading(true);
        setIsHeaderDetailError(false);
        const uri = `${
            requestConnectCareInventory.AssetHeaderDetail
        }?customerAccountId=${custAccountId}&inventoryItemId=${inventoryItemId}&serialNumber=${encodeURIComponent(
            serialNumber
        )}`;
        const response = await get<AssetHeaderDetail>(uri, true);

        if (response) {
            setAssetDetail(response);
        } else {
            setIsHeaderDetailError(true);
        }
        setIsLoading(false);
    }, [custAccountId, get, inventoryItemId, serialNumber]);

    /**
     * Gets data from the apis and sets the notes count.
     */
    const getNotesTabCount = useCallback(async () => {
        setIsNotesCountLoading(true);
        const uriNotesCount = `${
            requestConnectCareInventory.AssetNotesCount
        }?customerAccountId=${custAccountId}&inventoryItemId=${inventoryItemId}&serialNumber=${encodeURIComponent(
            serialNumber
        )}`;
        const response = await get<number>(uriNotesCount, true);
        const result = response as number;
        if (result >= 0) {
            setAssetNotesCount(result);
        } else {
            setIsNotesCountApiError(true);
        }
        setIsNotesCountLoading(false);
    }, [custAccountId, get, inventoryItemId, serialNumber]);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    /**
     * Gets data from the apis and sets @type {AssetHeaderDetail}.
     */
    useEffect(() => {
        if (!assetDetail && !isHeaderDetailError) {
            //Setting !isHeaderDetailError is preventing a rerender loop.
            getAssetHeaderDetails();

            if (!isHeaderDetailError) {
                getNotesTabCount();
            }
        }
    }, [assetDetail, getAssetHeaderDetails, getNotesTabCount, isHeaderDetailError]);

    let plannerOrNextService =
        assetDetail?.plannerActive === "Y" ? (
            <span style={{ textTransform: "uppercase" }}>{FormatDate(assetDetail?.nextService)}</span>
        ) : (
            <Link
                onClick={() => handleTabChangeForMaintenancePlan(tabIds.maintenancePlan)}
                to=""
                style={{
                    textDecoration: "none",
                    fontSize: "13px",
                    color: "blue.link",
                }}>
                {translations.enablePlanner}
            </Link>
        );

    let lastRepair =
        assetDetail?.lastRepair != null ? (
            <span style={{ textTransform: "uppercase" }}>{FormatDate(assetDetail?.lastRepair)}</span>
        ) : (
            <></>
        );

    return (
        <Box
            sx={{ backgroundColor: "bg.lightGray" }}
            data-testid="asset-tabs-page">
            {isHeaderDetailError && <AssetItemNotFound />}
            {serialNumber && assetDetail?.serialNumber && (
                <>
                    <BreadCrumb breadCrumbs={BreadCrumbList.assetDetails} />
                    {!hasViewAssets && <NotAuthorizeDisplay />}
                    {hasViewAssets && (
                        <Box
                            padding={2}
                            margin={2}
                            data-testid="asset-tabs-page-grid">
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    backgroundColor: "bg.white",
                                    borderRadius: 2,
                                    pb: 3,
                                    boxShadow: 1,
                                }}>
                                {isHeaderDetailError && (
                                    <Grid
                                        item
                                        xs={12}
                                        margin={2}>
                                        <Alert severity="error">{translations.apiError}</Alert>
                                    </Grid>
                                )}
                                {isLoading && <LinearProgress></LinearProgress>}
                                {!isLoading && (
                                    <>
                                        <Grid
                                            container
                                            padding={2}>
                                            <Grid
                                                item
                                                md={10}>
                                                <Grid>
                                                    <Typography
                                                        variant="body2"
                                                        color="font.gray">
                                                        {assetDetail?.customer ?? ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography
                                                        variant="h1"
                                                        color="font.darkBlue"
                                                        paddingY={1}>
                                                        {assetDetail?.category === "Instrument - Tray"
                                                            ? (assetDetail?.customerItemDescription ?? "")
                                                            : `${assetDetail?.manufacturer ?? ""} ${
                                                                  assetDetail?.productType ?? ""
                                                              }`}
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    {assetDetail?.model && (
                                                        <Grid>
                                                            <Typography
                                                                variant={"body2"}
                                                                color="font.darkBlue">
                                                                {translations.model + " : " + assetDetail?.model ?? ""}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid>
                                                        <Typography
                                                            variant={"body2"}
                                                            color="font.darkBlue">
                                                            {translations.serial + " : " + assetDetail?.serialNumber ??
                                                                ""}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {assetDetail?.entitlementValue && (
                                                    <Grid>
                                                        <Typography
                                                            variant={"h5"}
                                                            color="font.darkBlue"
                                                            textTransform="uppercase">
                                                            {assetDetail?.entitlementValue}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs>
                                                <Grid sx={{ float: "right" }}>
                                                    <Typography
                                                        marginLeft={1}
                                                        variant={"body2"}
                                                        color="font.darkBlue">
                                                        {translations.lastService + " : "}
                                                        {lastRepair}
                                                    </Typography>
                                                </Grid>

                                                <Grid sx={{ float: "right" }}>
                                                    <Typography
                                                        variant={"body2"}
                                                        color="font.darkBlue">
                                                        {translations.nextService + " : "}
                                                        {plannerOrNextService}
                                                    </Typography>
                                                </Grid>
                                                {assetDetail.canCreateServiceRequest && (
                                                    <Grid
                                                        item
                                                        marginTop={2}
                                                        sx={{ float: "right" }}>
                                                        {assetDetail.inventoryItemId !==
                                                            InventoryItemId.instrumentTray &&
                                                            assetDetail.productLine !== "MOBILE VAN" && (
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{ width: "200px" }}
                                                                    data-testid="assets-tab-create-service-request-btn"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/servicerequest/add-items/${
                                                                                srnTypeKeys.Serialized
                                                                            }/${custAccountId}/${encodeURIComponent(
                                                                                serialNumber ?? ""
                                                                            )}/${inventoryItemId}`
                                                                        )
                                                                    }>
                                                                    {translations.createServiceRequest}
                                                                </Button>
                                                            )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <ConnectCareTabs
                                            currentTab={currentTab}
                                            handleTabChange={handleTabChange}
                                            tabs={tabs}
                                        />

                                        {hasViewAllAssets &&
                                            currentTab?.tabId === tabIds.details &&
                                            !editDetailsTab && (
                                                <AssetDetail
                                                    customerAccountId={custAccountId}
                                                    assetDetailTab={assetDetailTab}
                                                    setAssetDetailTab={setAssetDetailTab}
                                                    setEditDetailsTab={setEditDetailsTab}
                                                    serialNumber={serialNumber}
                                                    inventoryItemId={inventoryItemId}
                                                    setPageKey={setPageKey}
                                                    isDetailTabReloaded={isDetailTabReloaded}
                                                    setIsDetailTabReloaded={setIsDetailTabReloaded}
                                                />
                                            )}
                                        {hasViewAllAssets && currentTab?.tabId === tabIds.details && editDetailsTab && (
                                            <AssetDetailEdit
                                                assetDetailTab={assetDetailTab}
                                                setEditDetailsTab={setEditDetailsTab}
                                                serialNumber={serialNumber}
                                                inventoryItemId={inventoryItemId}
                                                setIsDetailTabReloaded={setIsDetailTabReloaded}
                                                isIndirectFiltered={assetDetailTab?.isIndirectFiltered ?? false} //The only way to get to edit if asset details is open.
                                            />
                                        )}
                                        {currentTab?.tabId === tabIds.history && (
                                            <AssetHistoryDetails
                                                customerAccountId={custAccountId}
                                                serialNumber={serialNumber}
                                                inventoryItemId={inventoryItemId}
                                            />
                                        )}
                                        {hasViewAllAssets && currentTab?.tabId === tabIds.notes && (
                                            <AssetNotes
                                                customerAccountId={custAccountId}
                                                serialNumber={serialNumber}
                                                inventoryItemId={inventoryItemId}
                                            />
                                        )}
                                        {hasViewMaintenanceTab && currentTab?.tabId === tabIds.maintenancePlan && (
                                            <MaintenancePlan
                                                customerAccountId={custAccountId}
                                                serialNumber={serialNumber}
                                                inventoryItemId={inventoryItemId}
                                                setPageKey={setPageKey}
                                                editModeFlag={editModeFlag}
                                            />
                                        )}
                                        {showConfirmModal && (
                                            <ConfirmationModal
                                                show={showConfirmModal}
                                                onSecondaryButtonClick={() => handleDiscard(newTabValue)}
                                                onPrimaryButtonClick={() => handleEdit()}
                                                title={translations.confirmationModalTitle}
                                                contentMessage={translations.confirmationModalContent}
                                                secondaryButtonText={translations.confirmationModalDiscardChanges}
                                                primaryButtonText={translations.confirmationModalKeepEditing}
                                            />
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default AssetTabs;
