import { Box } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageTitles } from "../../common/SiteMap";

import { GridBackgroundColor, theme } from "../../theme/theme";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setInitialDataLoaded,
} from "../../redux/reducers/assets/deviceObservationSlice";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import { DeviceObservation } from "../../models/assets/DeviceObservation";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import DeviceObservationsGrids from "../../components/assets/deviceObservations/DeviceObservationsGrid";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";

export default function DeviceObservations() {
    const { t } = useTranslation();
    const { initialDataLoaded, searchString } = useAppSelector((state) => state.deviceObservation);
    const dispatch = useAppDispatch();
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const { get } = useFetch();
    const hasViewDeviceObservations = AuthLibrary.HasSubscriptionAccessToClaim(claimTypes.ViewDeviceObservations);

    const translations = {
        deviceObservation: t("Device Observations"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.deviceObservations)}`,
    };
    const getDeviceObservations = useCallback(async (): Promise<void> => {
        let uri = `${requestConnectCareCustomers.GetDeviceObservations}`;
        if (searchString?.length) {
            uri += `?searchstring=${encodeURIComponent(searchString)}`;
        }
        dispatch(dataRequested());
        const response = (await get<DeviceObservation[]>(uri, true)) as DeviceObservation[];
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [searchString, dispatch, get]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getDeviceObservations();
            dispatch(setInitialDataLoaded(true));
        }
    }, [dispatch, getDeviceObservations, initialDataLoaded]);

    useEffect(() => {
        if (selectedFacilities.length) {
            dispatch(setInitialDataLoaded(false));
        }
    }, [dispatch, selectedFacilities]);

    useEffect(() => {
        document.title = translations.documentTitle;
        return () => {
            dispatch(setInitialDataLoaded(false));
        };
    }, [dispatch, translations.documentTitle]);

    return (
        <Box style={GridBackgroundColor}>
            <Box p={theme.spacing(2)}>
                <Box py={theme.spacing(2)}>
                    {!hasViewDeviceObservations && <NotAuthorizeDisplay />}
                    {hasViewDeviceObservations && (
                        <DeviceObservationsGrids
                            data-testid="device-observations-grid"
                            getDeviceObservationList={getDeviceObservations}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
