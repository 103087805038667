import { Box, Button, Grid, LinearProgress, Link, useMediaQuery } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel, getRowClassName } from "../../../theme/stripedTable";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import { useDispatch } from "react-redux";
import { StoreState } from "../../../redux/store";
import { setGridFilter, setGridSort, setGridColumns } from "../../../redux/reducers/assets/deviceObservationSlice";
import GridHeader from "../../../component-library/GridHeader";
import { useNavigate } from "react-router-dom";
import { FormatDateTime } from "../../../common/Formatters";
import DeviceObservationSearch from "../DeviceObservationSearch";
import { FormEvent } from "react";
import { DeviceObservationProps } from "../../../models/assets/DeviceObservation";
import { GridBackgroundColor, theme } from "../../../theme/theme";
import { BreadCrumb } from "../../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../../common/SiteMap";
import { useAppSelector } from "../../../hooks/useReduxHooks";

export default function DeviceObservationsGrid(props: Readonly<DeviceObservationProps>) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        serial: t("SERIAL"),
        models: t("MODEL"),
        devicename: t("DEVICE NAME"),
        manufacture: t("MANUFACTURER"),
        observed: t("OBSERVED"),
        observations: t("OBSERVATIONS"),
        noSearchDataFound: t(`No Device Observations Found!`),
        apiError: t("System Error: API is not available at this time!"),
        search: t("Search"),
        title: t("Device Observations"),
    };
    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.getDeviceObservationList();
    };
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const { isLoading, isError, data, gridColumns, gridFilter, gridSort } = useAppSelector(
        (state: StoreState) => state.deviceObservation
    );

    const columns: GridColDef[] = [
        {
            field: "serial",
            headerName: translations.serial,
            minWidth: 200,
            maxWidth: 350,
            flex: 1,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    data-testid="serial-link"
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.customerAccountID}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serial
                            )}`
                        );
                    }}>
                    {params.row.serial}
                </Link>
            ),
        },
        {
            field: "model",
            headerName: translations.models,
            renderHeader: () => translations.models,
            minWidth: 200,
            maxWidth: 350,
        },
        {
            field: "deviceName",
            headerName: translations.devicename,
            renderHeader: () => translations.devicename,
            minWidth: 200,
            maxWidth: 350,
            align: "left",
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacture,
            renderHeader: () => translations.manufacture,
            minWidth: 200,
            maxWidth: 350,

            flex: 1,
        },
        {
            field: "observations",
            headerName: translations.observations,
            minWidth: 160,
            maxWidth: 350,
            // renderCell: (params) => (
            //     <Link
            //         data-testid="observations-link"
            //         onClick={() => {}}>
            //         {params.row.observations}
            //     </Link>
            // ),
            flex: 1,
            renderHeader: () => translations.observations,
        },
        {
            field: "observed",
            headerName: translations.observed,
            renderHeader: () => translations.observed,
            minWidth: 200,
            maxWidth: 350,
            flex: 1,
            renderCell: (params) => <>{FormatDateTime(params.row.observed)}</>,
        },
    ];

    return (
        <Grid  container>
            <Grid
                item
                xs>
                {!isLoading && isError && (
                    <GridHeader
                        title=""
                        hasError={isError}
                    />
                )}

                {!isError && (
                    <Box
                        style={GridBackgroundColor}
                        width={`calc(100vw - ${matches ? "132px" : "18px"})`}
                        data-testid="asset-list">
                        <BreadCrumb breadCrumbs={BreadCrumbList.deviceObservations} />

                        <Box p={theme.spacing(2)}>
                            <GridHeader
                                title={translations.title}
                                hasError={isError}
                                onFormSubmit={handleSearchItemSubmit}>
                                <Grid
                                    item
                                    lg={5}
                                    md={6}
                                    sm={5}
                                    xs={12}
                                    alignItems={"center"}
                                    width={"100%"}
                                    display={"flex"}>
                                    <DeviceObservationSearch />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    sm={3}
                                    xs={12}
                                    alignItems={"center"}
                                    display={"flex"}
                                    mx={"16px"}
                                    my={"4px"}>
                                    {/* search button */}
                                    <Button
                                        aria-label={translations.search}
                                        type="submit"
                                        variant="contained"
                                        size="small">
                                        {translations.search}
                                    </Button>
                                </Grid>
                            </GridHeader>
                            <GridAreaLayout data-testid="device-observations-grid">
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    getRowId={(row) => row.id}
                                    initialState={{
                                        filter: {
                                            filterModel: gridFilter,
                                        },
                                        sorting: {
                                            sortModel: gridSort,
                                        },
                                    }}
                                    columnVisibilityModel={gridColumns}
                                    rows={data}
                                    columns={columns}
                                    slots={{
                                        toolbar: () => CustomToolbar(exportExcelFileName.deviceObservations),
                                        loadingOverlay: LinearProgress,
                                        noRowsOverlay: () => NoRowsOverlay({ text: translations.noSearchDataFound }),
                                        filterPanel: StyledFilterPanel,
                                    }}
                                    slotProps={{
                                        columnsPanel: { sx: { textTransform: "capitalize" } },
                                    }}
                                    loading={isLoading}
                                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                    onColumnVisibilityModelChange={(columnModel) =>
                                        dispatch(setGridColumns(columnModel))
                                    }
                                    columnBuffer={5}
                                    density="compact"
                                    getRowClassName={(params) => getRowClassName(params)}
                                    editMode="cell"
                                />
                            </GridAreaLayout>
                        </Box>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
