import { useCallback, useEffect, useState } from "react";
import { widget, blue } from "../theme/colors";
import ChartAnnotation from "chartjs-plugin-annotation";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js";
import { CardContent, Grid, Box, CircularProgress, CardHeader, Typography, Dialog, CardActions } from "@mui/material";
import { AxisConfig, BarChart } from "@mui/x-charts";
import { MetricsColor } from "../common/MetricsColor";
import { useFetch } from "../services/useFetch";
import ErrorMessage from "../components/dashboard/ErrorMessage";
import { InsightMetrics } from "../models/InsightMetrics";
import { MetricsBox, MetricsCard } from "../theme/theme";
import i18n from "../i18n/config";

ChartJS.register(Title, Tooltip, Legend, ChartAnnotation);

export type BarChartValueType = {
    data: number[];
    label?: string;
    color?: string;
};

export interface IProps {
    title: string;
    subtitle?: string;
    errorMessage: string;
    apiUrl: string;
    remainingMetricsTitle?: string;
    showAll: boolean;
    includeUnkownItem: boolean;
}

export const InsightMetricsBarChart = (props: Readonly<IProps>) => {
    const { get } = useFetch();
    const [renderChart, setRenderChart] = useState<boolean>(true);
    const [metricsData, setMetricsData] = useState<BarChartValueType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isStatusError, setIsStatusError] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unknownItemCount, setUnknownItemCount] = useState<number>(0);

    /**
     * To generate a number greater than 999 with a comma. For example 15000 will be converted to 15,000
     * @param value is the number to which comma will be added
     * @returns the number with comma for value >999
     */
    const numberWithCommas = (value: number) => {
        return Intl.NumberFormat(i18n.language).format(value);
    };

    /**
     * Chooses a color for metrics bar chart.
     * @param index To get the color at index position
     * @returns The color
     */
    const insightMetricsColor = useCallback((index: number): string => {
        let color: string = MetricsColor[index] ?? widget.blue;
        return color;
    }, []);

    /**
     * Gets a piece of the bar chart and adds a label to it.
     * @param e The InsightMetrics
     * @returns {BarChartValueType} The bar chart data with labels.
     */
    const GetBarChartDataWithLabel = useCallback(
        (e: InsightMetrics, index: number): BarChartValueType => {
            let barChartValueType: BarChartValueType = {
                label: e.label,
                data: [e.count],
                color: insightMetricsColor(index),
            };

            return barChartValueType;
        },
        [insightMetricsColor]
    );

    /**
     * Gets all the data for our bar charts. Only display labels for the top 7.
     * @param showAll If true then show all the values on the bar chart. If false then show a fixed amount of items on the bar chart, with the rest being grouped together.
     * @param data Metrics Data to be displayed
     */
    const populateBarChartData = useCallback(
        (showAll: boolean, data: InsightMetrics[]) => {
            let insightMetricsData: BarChartValueType[] = [];
            if (data) {
                const labelCount = showAll ? data.length : 7; //how many items do you want on the legend?
                data.every(function (item, index) {
                    if (!props.includeUnkownItem && item.label === "Unknown") {
                        return true;
                    }
                    if (index < labelCount) {
                        //For the first n items add our label to our bar chart data.
                        insightMetricsData.push(GetBarChartDataWithLabel(item, index));
                        return true;
                    } else {
                        //Sum up the rest items that are being grouped together.
                        const res = data.filter((d, index) => index >= labelCount && d.count);
                        const sum = res.map((d) => d.count).reduce((a, b) => a + b, 0);
                        insightMetricsData.push(
                            GetBarChartDataWithLabel(
                                { count: sum, label: `+${data.length - labelCount} more...` },
                                index
                            )
                        );
                        return false;
                    }
                });
                setMetricsData(insightMetricsData);
                setTotalCount(data.map((d) => d.count).reduce((a, b) => a + b, 0));
                setRenderChart(true);
                setUnknownItemCount(
                    data
                        .filter((x) => x.label === "Unknown")
                        .map((d) => d.count)
                        .reduce((a, b) => a + b, 0)
                );
            }
        },
        [GetBarChartDataWithLabel, props.includeUnkownItem]
    );

    const getMetricsRecords = useCallback(async () => {
        setIsStatusError(false);
        const response = await get<InsightMetrics[]>(props.apiUrl, true);
        if (populateBarChartData?.length) {
            populateBarChartData(props.showAll, response as InsightMetrics[]);
        } else {
            setIsStatusError(true);
        }
        setIsStatusLoading(false);
    }, [get, populateBarChartData, props.apiUrl, props.showAll]);

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    useEffect(() => {
        getMetricsRecords();
    }, [getMetricsRecords]);

    return (
        <MetricsBox data-testid={"insight-metrics-box"}>
            {isStatusLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {(!isStatusLoading || isStatusError) && metricsData?.length <= 0 && (
                <Box
                    height={350}
                    data-testid={"error-box"}
                    sx={{
                        border: `1px solid ${blue.lightGrayishBlue}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <ErrorMessage
                        message={props.errorMessage}
                        colorValue={blue.lightGrayishBlue}
                    />
                </Box>
            )}
            {renderChart && !isStatusLoading && !isStatusError && !!metricsData?.length && (
                <Box onClick={handleDialogOpen}>
                    <Grid
                        data-testid="insight-metrics-graph"
                        container
                        textAlign={"center"}>
                        <Grid
                            item
                            xs>
                            <MetricsCard
                                key="insight-metrics-card"
                                data-testid="insight-metrics-card">
                                <CardHeader
                                    title={
                                        <>
                                            <Typography
                                                variant="detailLabel"
                                                color={widget.blackishblue}
                                                mt={"8px"}>
                                                {props.title}
                                            </Typography>
                                            {props.subtitle && (
                                                <Typography
                                                    variant="detailLabel"
                                                    color={widget.grey}
                                                    fontSize={10}>
                                                    {props.subtitle}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                />
                                <CardContent>
                                    <BarChart
                                        height={200}
                                        margin={{
                                            top: 0,
                                            bottom: 90,
                                        }}
                                        series={metricsData}
                                        xAxis={[
                                            {
                                                scaleType: "band",
                                                data: [`${props.title} ${props.subtitle ?? ""} (${totalCount})`],
                                                categoryGapRatio: 0,
                                            } as AxisConfig<"band">,
                                        ]}
                                        slotProps={{
                                            legend: {
                                                direction: "row",
                                                position: { vertical: "bottom", horizontal: "left" },
                                                padding: 0,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 12,
                                                markGap: 5,
                                                labelStyle: {
                                                    fontSize: 10,
                                                    marginTop: 10,
                                                },
                                            },
                                        }}
                                        leftAxis={null}
                                        bottomAxis={null}
                                    />
                                </CardContent>
                                <CardActions
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    {!!unknownItemCount && (
                                        <Typography color={widget.lightgray}>
                                            {numberWithCommas(unknownItemCount)} {props.remainingMetricsTitle}
                                        </Typography>
                                    )}
                                </CardActions>
                            </MetricsCard>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth={true}>
                <InsightMetricsBarChart
                    {...props}
                    showAll={true}
                />
            </Dialog>
        </MetricsBox>
    );
};
