export const claimTypes = {
    /**
     * 2.1.4 - View all facilities (Customer Accounts).
     */
    ViewFacilitiesAll: "View All Facilities",

    /**
     * 4.1.1 - View and Manage Users (Add, Edit, Deactivate, Reactivate, Configure Facility Access)
     */
    ViewUsers: "View All Users",

    /**
     * 4.1.2 - View and Manage Customers
     */
    ViewCustomerAdmin: "View Customer Admin",

    /** 4.2.1 - View Customer Users */
    ViewCustomerUsers: "View Customer Users",

    /** 4.2.2 - Add Customer Users */
    AddCustomerUsers: "Add Customer Users",

    /**
     * 5.1.2 - Send Items/Create a Service Request (SRN).
     */
    CreateSrn: "Create SRN",

    /**
     * 5.2.2 - Order Items - All (View "All Order Items" in submenu and access it).
     */
    ViewAllOrderItems: "View All Order Items",

    /**
     * 5.2.3 - Order Items - In Transit for Repair.
     */
    ViewInTransitForRepair: "View In Transit for Repair",

    /**
     * 5.2.4 - Order Items - In Transit for Repair.
     */
    ViewInRepair: "View In Repair",

    /**
     * 5.2.5 - Orders - All (View "All Orders" in submenu and access it).
     */
    ViewAllOrders: "View All Orders",

    /**
     * 5.2.6 - Orders - Van Visits.
     */
    ViewOnLocationVisits: "View On Location Visits",

    /**
     * 5.2.7 - View In Repair - Status
     */
    ViewInRepairStatus: "View In Repair - Status",

    /**
     * 5.2.8 - Orders Photos - Lab.
     */
    ViewOrderPhotosLab: "View Order Photos - Lab",

    /**
     * 5.2.9 - Orders Photos - On Location.
     */
    ViewOrderPhotosOnLocation: "View Order Photos - On Location",

    /**
     * 5.3.1 - View Orders Awaiting PO.
     */
    ViewPONumberRequired: "View PO Number Required",

    /**
     * 5.3.2 - Enter or Edit a Purchase Order Number.
     */
    EnterOrEditPo: "Enter or Edit PO",

    /**
     * 5.4.1 - View Estimates Awaiting Approval.
     */
    ViewEstimateApprovalNeeded: "View Estimate Approval Needed",

    /**
     * 5.4.2 - View Estimate Details - Observed tab.
     */
    ViewEstimateDetailsObservedTab: "View Estimate Details - Observed tab",

    /**
     * 5.4.3 - View Estimates Photos.
     */
    ViewEstimatePhotos: "View Estimate Photos",

    /**
     * 5.5.1 - View Shipments from STERIS.
     */
    ViewShipmentsFromSteris: "View Shipments from STERIS",

    /**
     * 5.6.1 - View Loaners Due for Return.
     */
    ViewLoanersDueForReturn: "View Loaners Due for Return",

    /**
     * 5.7.1 - View Equipment Request.
     */
    ViewEquipmentRequest: "View Equipment Request",

    /**
     * 5.7.2 - Request Equipment.
     */
    RequestEquipment: "Request Equipment",

    /**
     * 7.1.1 - View "All Assets".
     */
    ViewAllAssets: "View All Assets",

    /**
     * 7.1.2 - Assets - Due for Preventive Maintenance.
     */
    ViewDueForPreventiveMaintenance: "View Due for Preventive Maintenance",

    /**
     * 7.1.3 - Assets - Recommended for Service.
     */
    ViewRecommendedForService: "View Recommended for Service",

    /**
     * 7.1.4 - Assets - Device Preventive Maintenance.
     */
    ViewDevicePreventiveMaintenance: "View Device Preventive Maintenance",

    /**
     * 7.1.5 - Assets - Trays Serviced.
     */
    ViewTraysServiced: "View Trays Serviced",

    /**
     * 7.1.6 - Assets - Beyond Repair Devices.
     */
    ViewBeyondRepairDevices: "View Beyond Repair - Devices",

    /**
     * 7.1.7 - Assets - Beyond Repair Instruments.
     */
    ViewBeyondRepairInstruments: "View Beyond Repair - Instruments",

    /**
     * 7.1.8 - Assets Details- Maintenance tab.
     */
    ViewMaintenanceTab: "View Asset Details - Maintenance tab",

    /**
     * 7.1.9 - Assets - View Replaced Instruments.
     */
    ViewReplacedInstruments: "View Replaced Instruments",

    /**
     * 7.1.10 - Assets - Create a new Asset.
     */
    CreateAsset: "Create Asset",

    /**
     * 7.5.1 - Access - View Device Observations.
     */
    ViewDeviceObservations: "View Device Observations",

    /**
     * 8.1.2 - Access to Open Invoices.
     */
    ViewOpenInvoices: "View Open Invoices",

    /**
     * 8.1.3 - Access to Repair Credits.
     */
    ViewRepairCredits: "View Repair Credits",

    /**
     * 9.1.2 - Access to "All Events" in the submenu.
     */
    ViewAllEvents: "View All Events",

    /**
     * 9.1.3 - Access to "Education In-Services".
     */
    ViewEducationInservies: "View Education Inservices",

    /**
     * 9.1.4 - Access to "Business Reviews".
     */
    ViewBusinessReviews: "View Business Reviews",

    /**
     * 9.1.6 - Access to "Department Evaluations".
     */
    ViewDepartmentEvaluations: "View Department Evaluations",

    /**
     * 9.1.7 - Request an Education Inservice/Event.
     */
    RequestEducationEvent: "Request Education Event",

    /** 10.1.2 - View and execute "Repair History" Report. */
    ViewRepairHistoryReport: "View Repair History Report",

    /** 10.1.3 - View and execute "Equipment Planner AEM Tray" Report. */
    ViewEquipmentPlannerAEMTrayReport: "View Equipment Planner AEM Tray Report",

    /** 10.1.4 - View and execute "Equipment Planner AEM Device" Report. */
    ViewEquipmentPlannerAEMDeviceReport: "View Equipment Planner AEM Device Report",

    /** 10.1.5 - View Repair History Report Pricing.*/
    ViewRepairHistoryReportPricing: "View Repair History Report Pricing",

    /** 10.1.6 - View Preventative Maintenance Summary Report.*/
    ViewPMEventSummaryReport: "View PM Event Summary Report",

    /** 10.1.7 - View Preventative Maintenance Details Report.*/
    ViewPMEventDetailsReport: "View PM Event Details Report",

    /** 10.1.8 - View Preventative Damages Report.*/
    ViewPreventableDamagesReport: "View Preventable Damages Report",

    /**
     * 10.2.2 - View and execute "Recommended Never Serviced" Dashboard.
     */
    ViewRecommendedNeverServicedDashboard: "View Recommended Never Serviced Dashboard",

    /**
     * 10.2.3 - View and execute "Device Inspection History" Dashboard.
     */
    ViewDeviceInspectionHistoryDashboard: "View Device Inspection History Dashboard",

    /**
     * 10.2.4 - View and execute "Repair Frequency" Dashboard.
     */
    ViewRepairFrequencyDashboard: "View Repair Frequency Dashboard",

    /**
     * 14.1.2 - Access to view and edit Profile settings.
     */
    ViewProfile: "View Profile",

    /**
     * 17.1.1 - View "Tray Maintenance" widget on Dashboard.
     */
    ViewTrayMaintenanceWidget: "View Tray Maintenance Widget",

    /**
     * 17.1.2 - View "Flex Scope Maintenance Status" widget on Dashboard.
     */
    ViewFlexScopeMaintenanceStatusWidget: "View Flex Scope Maintenance Status Widget",

    /**
     * 17.1.3 - View "Surgical Device Maintenance Status" widget on Dashboard.
     */
    ViewSurgicalDeviceMaintenanceStatusWidget: "View Surgical Device Maintenance Status Widget",

    /**
     * 17.1.4 - View "Preventive Maintenance Results" widget on Dashboard.
     */
    ViewPreventiveMaintenanceResultsWidget: "View Preventive Maintenance Results Widget",

    /**
     * 18.1.1 - View Grid Page Metrics on grids.
     */
    ViewGridPageMetrics: "View Grid Page Metrics",

    /**
     * Ticket 26500 Added claim parameter for SSRS Reports
     */
    ViewReports: "View Reports",
};
