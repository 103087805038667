import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setSearchString } from "../../redux/reducers/assets/deviceObservationSlice";
import { StoreState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

export default function DeviceObservationSearch() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchString = useSelector((state: StoreState) => state.deviceObservation.searchString);

    const translations = {
        label: t("Device Observation Search"),
        placeholder: t("Search by Serial, Model, Device Name or Manufacturer."),
    };

    return (
        <Grid
            px={1}
            item
            sx={{ width: "100%", ".MuiOutlinedInput-notchedOutline": { border: "0px none" } }}>
            <TextField
                sx={{
                    width: "100%",
                    backgroundColor: "bg.lightBlue",
                    border: "none",
                    font: "lato",
                    fontWeight: "500",
                    fontSize: 13,
                    color: "font.textBlack",
                }}
                placeholder={translations.placeholder}
                size="small"
                id="deviceSearch"
                autoComplete="off"
                value={searchString}
                onChange={(e) => dispatch(setSearchString(e.target.value))}
                inputProps={{
                    "aria-label": `${translations.label}`,
                    "data-testid": "device-search-textfield",
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    );
}
