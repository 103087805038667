import { Box, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { AxisConfig, BarChart, BarSeriesType } from "@mui/x-charts";
import { widget } from "../theme/colors";
import { MakeOptional } from "@mui/x-charts/models/helpers";

interface IMetricsChart extends PropsWithChildren {
    readonly heading: string;
    readonly getSeries: MakeOptional<BarSeriesType, "type">[];
    readonly xAxis?: MakeOptional<AxisConfig, "id">[];
    readonly yAxis?: MakeOptional<AxisConfig, "id">[];
}

/**
 * Metrics Chart is used for showing metrics data as MUI BarChart.
 * @param {Object} props - The props.
 * @param {heading} props.heading - The heading for MUI BarChart.
 * @param {seriesData} [props.series] - Bar Chart Properties.
 * @param {xAxis} [props.xAxis] - X-Axis Configuration.
 * @param {yAxis} [props.yAxis] - y-Axis Configuration.
 * @returns The Metrics Chart component.
 * @description Creates the metrics bar chart html.
 */
export default function MetricsChart({ heading, getSeries, xAxis, yAxis }: IMetricsChart) {
    return (
        <Box height={"350px"}>
            <Typography
                variant="detailLabel"
                color={widget.blackishblue}
                padding={1}
                textAlign="center">
                {heading}
            </Typography>
            <Stack
                direction="row"
                alignItems="center"
                height={270}>
                <BarChart
                    height={200}
                    sx={{ border: "1" }}
                    tooltip={{ trigger: "item" }}
                    series={getSeries}
                    margin={{
                        top: 0,
                        bottom: 90,
                    }}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    slotProps={{
                        legend: {
                            direction: "row",
                            position: { vertical: "bottom", horizontal: "middle" },
                            padding: 0,
                            itemMarkWidth: 10,
                            itemMarkHeight: 12,
                            markGap: 5,
                            labelStyle: {
                                fontSize: 10,
                                marginTop: 5,
                            },
                        },
                    }}
                    leftAxis={null}
                    bottomAxis={null}
                />
            </Stack>
        </Box>
    );
}
