import {
    Box,
    Button,
    Divider,
    Typography,
    Stack,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Grid,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FacilityPmResultsSurgicalRecords } from "../../models/serviceDelivery/PmResultsSurgicalRecords";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import PieCenterLabel from "./PieCenterLabel";
import { useFetch } from "../../services/useFetch";
import { blue, text, widget } from "../../theme/colors";
import ErrorMessage from "./ErrorMessage";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { IRoundIconLookup, RoundIconLookupList } from "../../common/IRoundIconLookup";
import { PmiDiagnosis } from "../../common/PmiDiagnosis";

interface PieValueType{
    value: number;
    label: string;
    color: string;
};

export default function PreventiveMaintenance() {
    const { t } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [totalDevices, setTotalDevices] = useState<number>(0);
    const [data, setData] = useState<PieValueType[]>([]);
    const [preventiveMaintenanceRecords, setPreventiveMaintenanceRecords] = useState<
        FacilityPmResultsSurgicalRecords[]
    >([]);

    const translations = {
        ChartTitle: t("Preventive Maintenance Results"),
        CriticalPriority: t("Critical Priority"),
        ImmediateService: t("Immediate Service"),
        PreventiveService: t("Preventive Service"),
        Ok: t("OK"),
        DeviceWithNoPreviousInspection: t("No previous inspection record"),
        ErrorMessage: t("Data is unavailable or there is not enough data to display this metric."),
        devicesWithNoRecords: t("Devices with no previous service record"),
        view: t("View"),
    };

    /**
     * Populates the doughnut chart data state variable, and renders our chart with api data.
     * @param {PmResultsSurgicalRecords} preventiveMaintenanceRecord - Api response data.
     */
    const populatePieChartData = useCallback((preventiveMaintenanceRecord: FacilityPmResultsSurgicalRecords[]) => {
        setPreventiveMaintenanceRecords(preventiveMaintenanceRecord);
        let totalCount = 0;
        const mappedData = preventiveMaintenanceRecord?.map((item) => {
            totalCount += item.count;
            const roundIcon: IRoundIconLookup | undefined = RoundIconLookupList.find(
                (x) => x.displayText === item.diagnosisKey
            );
            return {
                value: item.count,
                label: item.diagnosisDescription,
                color: roundIcon?.iconColor ?? "",
            } as PieValueType;
        });
        setData(mappedData);
        setTotalDevices(totalCount);
    }, []);

    /**
     * Calls the device mainenance records api.
     */
    const getPreventiveMaintenanceRecords = useCallback(async () => {
        const response = await get<FacilityPmResultsSurgicalRecords[]>(
            requestConnectCareCustomers.PmResultSurgical,
            true
        );
        if (response) {
            populatePieChartData(response);
        }
    }, [get, populatePieChartData]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getPreventiveMaintenanceRecords(); //Get the data from the api.
            setInitialDataLoaded(true); //Once the page loads rely on selectedFacilitiesChanged to update our charts.
        }
    }, [getPreventiveMaintenanceRecords, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    return (
        <Grid
            data-testid="preventive-maintenance-graph"
            container
            minHeight={"90%"}
            textAlign={"center"}
            border={1}
            color={blue.lightGrayishBlue}
            my={2}>
            <Grid
                item
                xs>
                {totalDevices > 0 ? (
                    <Card
                        key="preventive-maintenance"
                        sx={{ boxShadow: 0 }}>
                        <CardHeader
                            sx={{ paddingBottom: 0 }}
                            title={
                                <Typography
                                    height={"60px"}
                                    variant="detailLabel"
                                    textTransform="uppercase"
                                    color={widget.blackishblue}
                                    mt={"8px"}>
                                    {translations.ChartTitle}
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Stack
                                data-testid="pm-pie-chart"
                                direction="row"
                                width="100%"
                                textAlign="center"
                                spacing={2}>
                                <Box flexGrow={1}>
                                    <PieChart
                                        margin={{
                                            top: 0,
                                            bottom: 90,
                                            left: 10,
                                            right: 5,
                                        }}
                                        series={[{ data, outerRadius: 90, innerRadius: 40 }]}
                                        height={300}
                                        slotProps={{
                                            legend: {
                                                direction: "row",
                                                position: { vertical: "bottom", horizontal: "middle" },
                                                padding: 0,
                                                itemMarkWidth: 45,
                                                itemMarkHeight: 12,
                                                markGap: 5,
                                                labelStyle: {
                                                    fontSize: 14,
                                                    marginTop: 10,
                                                },
                                            },
                                        }}>
                                        <PieCenterLabel>{totalDevices}</PieCenterLabel>
                                    </PieChart>
                                </Box>
                            </Stack>
                            <Typography
                                variant="body2"
                                color={text.textgray2}
                                my={2}>
                                {`${preventiveMaintenanceRecords.find((x) => x.diagnosisKey === PmiDiagnosis.InvOnly)?.count ?? 0} ${translations.devicesWithNoRecords}`}
                            </Typography>
                            <Divider />
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: "center",
                                mb: 1,
                            }}>
                            <Button
                                data-testid="preventive-maintenance-view"
                                variant="outlined2"
                                onClick={() => {
                                    navigate(`/assets/devicepm`);
                                }}>
                                {translations.view}
                            </Button>
                        </CardActions>
                    </Card>
                ) : (
                    <Grid
                        xs
                        item
                        mt={20}>
                        <ErrorMessage message={translations.ErrorMessage} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
